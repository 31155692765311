.c-info{
	color: #fff;
	padding: 15px 0;
	&__item{
		margin-bottom: 20px;
	}
	&__title{
		text-transform: uppercase;
		margin-bottom: 15px;
	}
	&__list[class]{
		list-style: decimal;
		padding-left: 30px;
		margin: 0;
	}
	&__list{
		&-item{
			color: #969dcd;
			margin-bottom: 10px;
		}
	}
}