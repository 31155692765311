.c-reviews{
	padding: 15px 0;
	&__item{
		padding: 15px 15px;
		padding-bottom: 25px;
		border-bottom: 1px solid #283551;
		&:last-child{
			border-bottom: none;
		}
	}
	&__name{
		color: #fff;
	}
	&__data{
		color: #28305f;
	}
	&__text{
		color: #969dcd;
	}
	&__answer{
		border: none;
		background-color: #252f67;
		border-radius: 3px;
		color: #fff;
		padding: 6px 15px;
		display: block;
		cursor: pointer;
		margin-left: auto;
	}
}