.c-page-sidebar{
	width: 100%;
	padding: 15px;
	&__top{
		margin-top: 10px;
	}
	&__btn{
		border: none;
		background-color: transparent;
		color: #fff;
		box-shadow: 0 3px 3px rgba(0,0,0,0.3);
		text-align: center;
		padding: 10px 25px;
		cursor: pointer;
		margin-bottom: 8px;
		display: block;
		width: 100%;
		transition: all .4s;
		&:hover{
			filter: brightness(120%);
		}
		img{
			margin: 0 auto;
		}
	}
	&__chat-link{
		background: #3F3C67;
		// width: 44%;
		margin-right: 5px;
		border-radius: 5px 0px 0px 5px;
	}
	&__login{
		// width: 66%;
		background: #30294A;
		border-radius: 0px 5px 5px 0px;
		color: #3E3DAA;
	}
	&__chat{
		height: 260px;
		overflow: auto;
		margin-bottom: 40px;
		&-item{
			padding-top: 10px;
			padding-bottom: 20px;
			padding-left: 10px;
			margin-bottom: 5px;
			background-color: #74518d1f;
			border-radius: 5px;
		}
		&-name{
			text-transform: uppercase;
			color: #fff;
			text-shadow: 1px 2px 0px #222, 0 0 1em #333;
		}
		&-text{
			color: #8570AA;
			text-shadow: 1px 2px 0px #222, 0 0 1em #333;
		}
	}
	&__bottom{
		display: flex;
		justify-content: space-between;
	}
	&__play{
		text-transform: uppercase;
		color: #fff;
		text-decoration: none;
	}
	&__num{
		color: #95C71F;
		position: relative;
		padding-left: 15px;
		&::before{
			content: '';
			position: absolute;
			background-image: url(../img/bosh.png);
			width: 13px;
			height: 15px;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
		}
	}
}
.o-page-sidebar__color-red{
	color: #ff0036;
}
.o-page-sidebar__color-blue{
	color: #346dff;

}
.o-allblock-page{
	display: block;
}
@media(min-width: 400px){
	.o-allblock-page{
		display: flex;
	}
	.c-page-sidebar{
		width: 100%;
		max-width: 175px;
		padding-right: 0;
	}
}
@media(min-width: 550px){
	.c-page-sidebar{
		max-width: 345px;
		width: 100%;
		position: sticky;
		top: 0;
		left: 0;
		height: 100%;
		&__top{
			display: flex;
		}
	}
}

