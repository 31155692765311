.c-page-bid{
	label{
		color: #fff;
		text-transform: uppercase;
	}
	input{
		background-color: #333053;
		border: none;
		font-size: 20px;
	}
	&__info{
		display: flex;
		background-color: #3F3C6B;
		border-top: none;
		border: 1px solid #DA0843;
	}
	&__img{
		min-height: 210px;
		border: 1px solid #DA0843;
	}
	&__box{
		img{
			display: none;
		}
	}
	&__add{
		width: 100%;
		padding: 50px 5px 10px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		border-right: none;
		&-inner{
			display: flex;
			flex-direction: column;
			align-items: center;
		}
		&-link{
			background-color: #FF0034;
			text-transform: uppercase;
			margin: 0 auto;
		}
	}
	&__line{
		display: flex;
		flex-wrap: wrap;
		color: #fff;
		justify-content: space-between;
		background: linear-gradient(to left, #ff0034, #0E5DD0);
		text-transform: uppercase;
		padding: 15px;
		margin-bottom: 15px;
		border-radius: 3px;
		margin-top: 5px;
		@media(min-width: 1440px){
			position: absolute;
			bottom: -30px;
			width: 100%;
		}
	}
}
@media(min-width: 400px){
	.c-page-bid{
		&__add{
			&-inner{
				display: flex;
				justify-content: center;
			}
		}
		&__input{
			margin-right: 5px;
		}
	}
}
@media(min-width: 768px){
	.c-page-bid{
		&__info{
			width: 100%;
			height: 210px;
		}
		&__add{
			width: 50%;
			justify-content: center;
			padding-bottom: 30px;
		}
		&__box{
			width: 50%;
			height: 100%;
			border-right: 1px solid #da0843;
			flex-wrap: wrap;
			img{
				display: block;
				width: 100%;
				height: 100%;
			}
		}
	}
}
@media(min-width: 930px){
	.c-page-bid{
		height: 210px;
		padding: 0;
		margin-bottom: 15px;
		&__img{
			width: 80%;
		}
	}
}
@media(min-width: 1440px){
	.c-page-bid{
		margin-bottom: 0;
		&__img{
			width: 60%;
		}
	}
	.c-bid-history-wrapper{
		padding: 30px 0;
	}
}