.c-reference-table{
	background: #2b3569;
	padding: 15px;
	margin-top: 20px;
	margin-bottom: 20px;
	width: 100%;
	&__box{
		border-top: 2px solid #1647b1;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		padding-top: 5px;
		padding-bottom: 5px;
		&:last-child{
			border-bottom: 2px solid #1647b1;
		}
	}
	&__title{
		color: #fff;
		padding: 10px 30px;
		width: 100%;
	}
	&__content{
		color: #969dcd;
		width: 100%;
		font-size: 15px;
	}
}
@media(min-width: 550px){
	.c-reference-table{
		&__title{
			width: 50%;
		}
		&__content{
			width: 50%;
		}
	}
}
.o-stairs-decor,
.o-decor-cards{
	position: relative;
	&::before{
		content: '';
		position: absolute;
		left: 2px;
		top: 50%;
		transform: translateY(-50%);
	}
}
.o-stairs-decor::before{
	background-image: url(../img/ladder.svg);
	width: 25px;
	height: 25px;
}
.o-decor-cards::before{
	background-image: url(../img/playing-cards.svg);
	width: 20px;
	height: 20px;
}
