.c-footer{
	background: #13183d;
	padding: 40px 15px;
	color: #969dcd;
	text-align: center;
	font-size: 15px;
	&__logo{
		margin-bottom: 20px;
		a{
			display: inline-block;
		}
	}
	&__list{
		list-style: none;
	}
	&__link{
		color: #969dcd;
		text-decoration: none;
	}
}
@media(min-width: 768px){
	.c-footer{
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		text-align: left;
		padding-left: 40px;
		padding-right: 40px;
		&__logo,
		&__list{
			width: 50%;
			margin-bottom: 50px;
		}
		&__list{
			text-align: right;
		}
		&__item{
			display: inline-block;
			margin-left: 20px;
		}
		&__copyright{
			margin: 0 auto;
		}
	}
}