.c-history{
	border: 1px solid #30365d;
	height: 280px;
	width: 100%;
	padding: 10px;
	overflow: hidden;
	&__title{
		text-align: center;
		color: #fff;
	}
	&-list{
		height: 240px;
		overflow-y: auto;
		text-align: center;
		&::-webkit-scrollbar {
			width: 4px;
			background: #2f3661;
		}
		&::-webkit-scrollbar-thumb{
			background: #2f3661;
		}
		&::-webkit-scrollbar-track {
			background: #1d234f;
		}
	}
	&__item{
		padding: 11px 20px 11px 20px;
		&:nth-child(odd){
			background: #1d2450;
		}
	}
}
.o-arrow-down,
.o-arrow-blue,
.o-arrow-azure{
	position: relative;
	display: inline-block;
	&::before{
		content: '';
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: -20px;
		width: 15px;
		height: 15px;
	}
}
.o-arrow-blue,
.o-arrow-azure{
	&::before{
		transform: translateY(-50%) rotate(180deg);
	}
}
.o-arrow-blue{
	color: #547bff;
	&::before{
		background-image: url(../img/down-arrow-blue.svg);
	}
}
.o-arrow-azure{
	color: #54d1ff;
	&::before{
		background-image: url(../img/down-arrow-azure.svg);
	}
}
.o-arrow-down{
	color: #ff547c;
	&::before{
		background-image: url(../img/down-arrow-red.svg);
	}
}
@media(min-width: 1440px){
	.c-history{
		max-width: 240px;
		min-height: 300px;
		width: 100%;
		margin-left: 10px;
	}
}