.c-bid{
	color: #fff;
	padding: 30px 0px;
	position: relative;
	&__img{
		min-height: 300px;
		width: 100%;
		background-color: #ccc;
		background-image: url("../img/canvas.png");
		background-image: -webkit-image-set(url("../img/webp/canvas.webp") 1x, url("../img/webp/canvas.webp") 2x);
		background-image: image-set(url("../img/canvas.png") 1x, url("../img/canvas.png") 2x);
		background-repeat: no-repeat;
		background-size: cover;
		background-position: top right;
	}
	&__info{
		background: #252f67;
		border-top: 3px solid #0a55f7;
		position: relative;
	}
	&__add{
		padding: 50px 30px 20px;
		max-width: 500px;
		border-right: 1px solid #1b2047;
		&-link{
			display: inline-block;
			text-decoration: none;
			color: #fff;
			padding: 4px 30px;
			width: 100%;
			background: #32a668;
			margin-top: 20px;
			text-align: center;
			transition: all .5s;
			border-radius: 3px;
			max-width: 242px;
			width: 100%;
			&:hover{
				filter: brightness(120%);
			}
		}
	}
	label{
		display: block;
		color: #969DCD;
	}
	input{
		border: 1px solid #9397c7;
		background-color: #181f4b;
		padding: 0px 10px 0px 10px;
		border-radius: 5px;
		font-size: 13px;
		outline: none;
		display: block;
		height: 34px;
		color: #fff;
		margin-bottom: 10px;
		max-width: 242px;
		width: 100%;
	}
	&__decor{
		position: absolute;
		top: -30px;
		left: 50%;
		transform: translateX(-50%);
		width: 60px;
		height: 60px;
		background: #0a55f7;
		border-radius: 50%;
		&::before{
			content: '';
			position: absolute;
			background-image: url('../img/icons/right-icon.png');
			width: 20px;
			height: 20px;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%) rotate(90deg);
		}
	}
	&__input{
		position: relative;
		max-width: 242px;
		&-num{
			position: absolute;
			right: 55px;
			top: 50%;
			transform: translateY(-50%);
			color: #969dcd;
			font-size: 13px;
		}
	}
}
.c-btn-num{
	position: absolute;
	right: 0;
	top: 0;
	width: 22px;
	line-height: 22px;
	border: none;
	text-align: center;
	color: #fff;
	cursor: pointer;
	background-color: #28305f;
	transition: background-color 100ms ease-in;
	top: 50%;
	transform: translateY(-50%);
	&:hover{
		background-color: #454f88;
		transition: background-color 100ms ease-in;
	}
}
.c-btn-decrement{
	right: 30px;
}
.c-btn-increment{
	right: 5px;
}
@media(min-width: 550px){
	.c-bid{
		&__add{
			width: 50%;
			max-width: none;
		}
	}
}
@media(min-width: 930px){
	.c-bid{
		display: flex;
		width: 100%;
		&__img,
		&__info{
			width: 50%;
		}
		&__decor{
			left: -30px;
			top: 50%;
			transform: translateY(-50%);
			&::before{
				transform: translate(-50%, -50%) rotate(0);
			}
		}
	}
}
@media(min-width: 1440px){
	.c-bid-history-wrapper{
		display: flex;
		align-items: center;
		position: relative;
	}
	.c-bid{
		&__img{
			width: 100%;
		}
		&__info{
			width: 60%;
		}
	}
}