.c-page-history{
	border: 1px solid #da0843;
	background-color: #3F3C6B;
	.c-history__item{
		background-color: #333053;
	}
	.c-history__title{
		padding: 5px;
		text-transform: uppercase;
	}
	.c-history__item{
		padding: 0;
		span::before{
			display: none;
		}
	}
}
@media(min-width: 1440px){
	.c-page-history{
		height: 210px;
		min-height: auto;
	}
}