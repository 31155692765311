.c-questions{
	color: #fff;
	background: #2b3569;
	padding: 15px;
	&__content{
		margin-bottom: 10px;
		p{
			padding-left: 10px;
		}
	}
	&__question{
		position: relative;
		outline: none;
		cursor: pointer;
		padding: 10px;
		padding-left: 35px;
		background-color: #1647b152;
		border: 1px solid #19679b;
		border-radius: 4px;
		margin-bottom: 10px;
		img{
			display: none;
		}
		&::-webkit-details-marker{
			display: none;
		}
		&::before{
			content: "";
			left: 10px;
			position: absolute;
			background-image: url(../img/next.svg);
			width: 15px;
			height: 15px;
			top: 50%;
			transform: translateY(-50%);
		}
	}
}
.c-questions__content[open]{
	.c-questions__question::before{
		position: absolute;
		transform: translateY(-50%) rotate(90deg);
	}
}
@media(min-width: 600px){
	.c-questions{
		&__question{
			padding: 10px 20px 10px 70px;
			img{
				position: absolute;
				left: 45px;
				top: 50%;
				transform: translateY(-50%);
				width: 18px;
				height: 18px;
				display: block;
			}
		}
	}
}