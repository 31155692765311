*,*::before,*::after {
  box-sizing: border-box;
}
ul[class],ol[class] {
  padding: 0;
  margin: 0;
}
body,h1,h2,h3,h4,p,li,figure,figcaption,blockquote,dl,dd {
  margin: 0;
}
body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}
ul[class],ol[class] {
  list-style: none;
}
a:not([class]) {
  text-decoration-skip-ink: auto;
}
img {
  max-width: 100%;
  display: block;
}
section > * + *, article > * + * {
  margin-top: 1em;
}
input,button,textarea,select {
  font: inherit;
}
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
::-webkit-scrollbar{
  width: 9px;
}
::-webkit-scrollbar-thumb {
    background: #3d4572;
}
::-webkit-scrollbar-track {
    background: #2c3462;
}
body{
  font-family: sans-serif;
  background: #181f4b;
}
.o-allblock{
  display: flex;
}
.o-allblock-page{
  background-color: #241C35;
}
.c-wrapper-wide{
  width: 100%;
  padding: 0 10px;
  @media(min-width: 768px){
    padding: 0 20px;
  }
}
.js-burger{
  width: 22px;
  height: 18px;
  cursor: pointer;
  position: relative;
  order: -1;
  span{
    top: 50%;
    transform: translateY(-50%);
  }
  span,
  &::before,
  &::after{
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #969dcd;
    border-radius: 2px;
  }
  &::after{
    bottom: 0;
  }
}