.c-sidebar{
	background: linear-gradient(to right, #2c58dd, #2a44c8);
	box-shadow: 0px 0px 30px rgba(0,0,0,0.2);
	width: 55px;
	height: 100vh;
	padding: 30px 0px 30px 0px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	position: sticky;
	left: 0;
	top: 0;
	bottom: 0;
	&__logo{
		width: 40px;
		margin-bottom: 30px;
	}
	&__list{
		display: flex;
    flex-direction: column;
    align-items: center;
	}
	&__item{
		display: block;
		padding: 10px 0;
	}
	&__link{
		margin: 0 3px;
		display: block;
	}
	&__icon{
		opacity: .3;
		transition: all .5s;
		&:hover{
			opacity: 1;
		}
	}
}
.c-profile-icon{
	opacity: 1;
}
.c-sidebar-social{
	padding-top: 20px;
}