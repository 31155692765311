.c-rublix-header{
	margin: 0;
	position: relative;
	background-color: #282339;
	.c-header__btn-login{
		background: #3F3C67;
	}
	.c-menu__list.is-active{
		background-color: #282339;
	}
}