.c-page-reference{
	.c-reference-table{
		background-color: #333053;
	}
	.c-reference-table__box {
		border-top: 2px solid #673ab7;
	}
	.c-reference-table__box:last-child {
    border-bottom: 2px solid #673ab7;
}
}