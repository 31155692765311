.c-footer-page{
	&__text{
		span{
			display: block;
			margin-bottom: 20px;
		}
	}
}
@media(min-width: 768px){
	.c-footer-page{
		justify-content: center;
		text-align: center;
		&__logo,
		&__text{
			width: 100%;
		}
	}
}