.c-header-page{
	color: #969dcd;
	padding: 20px 15px;
	margin: 0 -10px;
	position: relative;
	&__top{
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 20px;
	}
	&__info{
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 30px;
	}
	&__sign{
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
.c-header-page{
	&__text{
		text-transform: uppercase;
		display: none;
	}
	&__details{
		p{
			color: #fff;
		}
	}
	&__players,
	&__online{
		position: relative;
		padding-left: 15px;
	}
	&__players{
		&::before{
			background-color: #16afc5;
		}
	}
	&__online{
		&::before{
			background-color: #2bbf2bb8;
		}
	}
	&__sign{
		p{
			display: none;
		}
		a{
			text-decoration: none;
			color: #fff;
			display: block;
			background-color: #0ce20c85;
			padding: 10px 30px;
			border-radius: 20px;
			white-space: nowrap;
		}
	}
}
.c-page-menu.is-active{
	top: 75px;
	padding: 0 15px;
}
.js-burger.page-header-burger{
	order: 1;
}
.o-position-decor::before{
	position: absolute;
	content: "";
	left: 0;
	top: 50%;
	transform: translateY(-50%);
	width: 12px;
	height: 12px;
	border-radius: 50%;
}
@media(min-width: 410px){
	.c-header-page{
		&__text{
				display: block;
		}
		&__info{
			justify-content: space-around;
		}
		&__sign{
			p{
				display: block;
				margin-right: 15px;
			}
		}
	}
}
@media(min-width: 768px){
	.c-header-page{
		&__inner{
			display: flex;
			justify-content: center;
			align-items: center;
		}
		&__wrap{
			max-width: 300px;
		}
		&__info{
			justify-content: space-between;
		}
		&__box{
			margin-right: 100px;
		}
		&__sign{
			max-width: 400px;
			p{
				text-transform: uppercase;
				font-size: 14px;	
			}
		}
	}
	.c-page-menu{
		display: block;
		box-shadow: 10px 10px 5px 0px rgba(71,82,204,0.42);
		.c-menu__item{
			padding: 0 20px;
		}
	}
}