.c-header{
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	background: #2b3569;
	margin: 0 -10px;
	padding: 10px;
	position: sticky;
	top: 0px;
	z-index: 3;
	&__box{
		margin-left: 10px;
	}
	&__btn{
		color: #969dcd;
		text-decoration: none;
		transition: all .5s;
		display: inline-block;
		&-login{
			background: #3b498b;
			color: #fff;
			padding: 8px 20px;
			border-radius: 3px;
			text-transform: uppercase;
			font-size: 15px;
			margin-left: 10px;
			&:hover{
				filter: brightness(130%);
			}
		}
		&:hover{
			color: #fff;
		}
	}
}
.c-menu{
	&__list{
		display: none;
		&.is-active{
			display: block;
			position: absolute;
			background: #2b3569;
			margin-top: 0;
			z-index: 3;
			left: 0;
			right: 0;
			top: 100%;
			padding-left: 10px;
		}
	}
	&__item{
		margin-right: 20px;
		position: relative;
		&-bonus,
		&-promo{
			padding-left: 25px;
		}
		&-bonus::before,
		&-promo::before{
			position: absolute;
			content: '';
			left: 0;
			top: 50%;
			transform: translateY(-50%);
		}
		&-bonus::before{
			background-image: url('../img/icons/bonus.png');
			width: 18px;
			height: 18px;
		}
		&-promo::before{
			background-image: url('../img/icons/cards-hr.png');
			width: 20px;
			height: 14px;
		}
	}
	&__link{
		display: block;
		text-decoration: none;
		color: #969dcd;
		transition: all .5s;
		padding: 10px 0px 10px 0;
		border-bottom: 1px solid rgba(255,255,255,0.1);
		&-promo{
			color: #ffb424;
		}
		&:hover{
			color: #fff;
		}
	}
}
@media(min-width: 340px){
	.c-header{
		&__btn{
			&-login{
				margin-left: 20px;
			}
		}
	}
}
@media(min-width: 768px){
	.c-header{
		margin-left: -20px;
		margin-right: -20px;
		padding: 10px 20px;
		&__btn{
			&-login{
				padding-left: 30px;
				padding-right: 30px;
			}
		}
	}
	.c-menu{
		&__list{
			display: flex;
		}
		&__link{
			border-bottom: none;
		}
	}
	.js-burger{
		display: none;
	}
}