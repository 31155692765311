.c-invested{
	background: linear-gradient(to left, #ff0034, #0E5DD0);
	padding: 15px;
	border-radius: 5px;
	color: #fff;
	display: flex;
	flex-wrap: wrap;
	&__left{
		width: 100%;
	}
	&__right{
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
	}
	&__form{
		width: 100%;
	}
	&__subtitle{
		border-bottom: 1px solid #fff;
		padding-bottom: 15px;
		margin-bottom: 10px;
	}
	&__rate{
		text-transform: uppercase;
	}
	&__text{
		text-shadow: 1px 2px 0px #222, 0 0 1em #333;
	}
	&__chance{
		border: 1px solid #fff;
		border-radius: 5px;
		padding: 20px 30px;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		margin: 15px 15px;
	}
	&__interest{
		font-size: 32px;
		margin-left: 10px;
	}
	&__input{
		border: none;
		background-color: transparent;
		border-bottom: 1px solid #fff;
		outline: none;
		display: block;
		width: 100%;
		padding: 0 15px;
		color: #fff;
		&::placeholder{
			text-align: center;
		}
	}
	&__btn{
		text-transform: uppercase;
		border: none;
		background-color: #006DFF;
		border-radius: 5px;
		color: #fff;
		display: block;
		padding: 10px 20px;
		width: 100%;
		margin-top: 15px;
		cursor: pointer;
	}
}
@media(min-width: 768px){
	.c-invested{
		&__right{
			flex-wrap: nowrap;
		}
	}
}
@media(min-width: 1024px){
	.c-invested{
		flex-wrap: nowrap;
		align-items: center;
		&__left{
			width: 40%;
		}
		&__right{
			width: 60%;
		}
	}
}